import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { acceptCounteredOffer } from 'shared/find-loads/redux/find-loads.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useAjaxRequest } from 'app/hooks/ajax/use-ajax-request.hook';
import { acceptCounteredOfferFromOffers } from 'shared/offers/redux/offers.actions';

export type UseAcceptCounteredOfferHook = [() => void, boolean];

export const useAcceptCounteredOffer = (offerId: number, loadNumber: number, loaderName?: string, isFromOffers: boolean = false): UseAcceptCounteredOfferHook => {
  const repo = useRepository(AvailableLoadsRepository);
  const toasts = useToastManager();
  const resources = useResources();
  const dispatch = useDispatch();

  const [isOfferAccepted, setIsOfferAccepted] = useState<boolean>(null);

  const handleError = useCallback(() => {
    setIsOfferAccepted(false);
    return toasts.error([resources.CANNOT_PROCESS_YOUR_REQUEST_AT_THIS_TIME_PLEASE_TR]);
  }, [toasts, resources]);

  const handleSuccess = useCallback(() => {
    setIsOfferAccepted(true);
    if (isFromOffers) {
      return dispatch(acceptCounteredOfferFromOffers({loadNumber: loadNumber, offerId: offerId}));
    } else {
      return dispatch(acceptCounteredOffer(loadNumber));
    }
  }, [dispatch, loadNumber, offerId]);

  const handleAcceptOffer = useCallback(() => repo.acceptOffer(offerId), [repo, offerId]);

  const acceptOffer = useAjaxRequest(
    handleAcceptOffer,
    handleSuccess,
    [handleAcceptOffer],
    handleError,
    loaderName
  );

  const acceptOfferCallback = useCallback(() => {
      acceptOffer();
  }, [acceptOffer, isOfferAccepted, offerId]);
  return [acceptOfferCallback, isOfferAccepted];
};

import { useCallback } from 'react';
import { publishBookActionEvent, publishOfferActionEvent } from 'app/repositories/analytics.repository';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const usePublishBookAction = () => {
  const { user, searchCorrelationId } = useSelector(s => ({
    user: s.auth.user,
    searchCorrelationId: s.findLoads.searchCorrelationId
  }));
  const { findLoadsSendAnalyticsWeb } = useFlags();

  const publishBookAction = useCallback((bookActionType: BookActionType, loadNumber, bookPrice: number, currencyCode: string, success?: boolean, statusCode?: number) => {
    if (findLoadsSendAnalyticsWeb) {
      const searchId = window.location.pathname == "/find-loads/single" ? searchCorrelationId : null;
      publishBookActionEvent(bookActionType, loadNumber.toString(), bookPrice, currencyCode, searchId, user, success, statusCode);;
    }
  }, [user, searchCorrelationId, findLoadsSendAnalyticsWeb]);

  return publishBookAction;
}

export const usePublishOfferAction = () => {
  const { user, searchCorrelationId } = useSelector(s => ({
    user: s.auth.user,
    searchCorrelationId: s.findLoads.searchCorrelationId
  }));
  const { findLoadsSendAnalyticsWeb } = useFlags();

  const publishOfferAction = useCallback((offerActionType: OfferActionType, loadNumber, offerPrice?: number, currencyCode?: string) => {
    if (findLoadsSendAnalyticsWeb) {
      const searchId = window.location.pathname == "/find-loads/single" ? searchCorrelationId : null;
      publishOfferActionEvent(offerActionType, loadNumber.toString(), searchId, user, offerPrice, currencyCode);;
    }
  }, [user, searchCorrelationId, findLoadsSendAnalyticsWeb]);

  return publishOfferAction;
}
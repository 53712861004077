import { useDispatch } from 'react-redux';
import { TranslationJSX } from 'shared/components/translation/translation.component';
import { useTimer } from 'app/hooks/use-timer.hook';
import { OfferState } from '../hooks/use-carrier-offer.hook';
import { updateOfferStatus } from 'shared/find-loads/redux/find-loads.actions';
import { updateOfferStatusOnOffersStore } from 'shared/offers/redux/offers.actions';
import { OfferStatus } from 'shared/enums/offer-status.enum';

export interface OfferExpirationTimerProps {
  offerState: OfferState;
}

export const OfferExpirationTimer = ({ offerState }: OfferExpirationTimerProps) => shouldShowTimer(offerState) && <OfferExpirationTimerComponent offerState={offerState} />;

const OfferExpirationTimerComponent = ({ offerState }: OfferExpirationTimerProps) => {
  const dispatch = useDispatch();
  const timeLeft = useTimer({
    timerExpirationDate: offerState?.offerStatus?.offerExpirationDate,
    onTimeout: () => {
      dispatch(updateOfferStatus({
        loadNumber: offerState?.carrierOffer?.loadNumber,
        updatedStatus: 'EXPIRED'
      }));
      dispatch(updateOfferStatusOnOffersStore({
        loadNumber: offerState?.carrierOffer?.loadNumber,
        offerId: offerState?.carrierOffer?.latestOffer?.offerId,
        updatedStatus: OfferStatus.EXPIRED,
      }));
    }
  });
  return <TranslationJSX resource="EXPIRES_IN" replacements={[<span className="time-left">{timeLeft}</span>]} />
}

const shouldShowTimer = (offerState: OfferState): boolean => {
  const hasOffer: boolean = Boolean(offerState?.carrierOffer?.latestOffer);
  const { offerStatus } = offerState ?? {};
  return hasOffer
    && (offerStatus?.isOfferAcceptedByDataScience || offerStatus?.isOfferCountered)
    && !offerStatus?.isOfferExpired;
};

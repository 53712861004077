import { useCallback } from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useAnalyticsTrack } from '@chr/react-analytics';
import { useAnalyticsEventMetaData } from '@features/analytics/hooks/use-analytics-event-metadata.hook';
import { useSearchEvents, useSortActionEvents } from '@features/analytics/redux/analytics-v2.selectors';
import { mapToAdobeSearchCriteria, mapToAdobeLoadDetail } from '@features/analytics/utils/adobe-search-events-data-mapper';
import { generateEventSequence } from '@features/analytics/utils/event-sequence-generator';
import { EVENT_TYPES } from '@features/analytics/constants';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { ReloadEventData } from '@features/analytics/redux/analytics-v2.actions';

interface OptionsBook {
  cashAdvanceOptIn?: boolean
}

export const useBookAnalytics = () => {
  const track = useAnalyticsTrack();
  const eventMetaData = useAnalyticsEventMetaData();
  const { sortBy, sortOrder } = useSortActionEvents();
  const { searchCriteria, searchCorrelationId } = useSearchEvents();

  const trackBookEvent = useCallback((eventType: EVENT_TYPES, load: AvailableLoadSummary, position?: number, reloadData?: ReloadEventData, statusCode?: number, options?: OptionsBook) => {
    const { timeGMT, sequenceNumber } = generateEventSequence();
    const search = mapToAdobeSearchCriteria(searchCriteria);
    const latestOffer = load.carrierOffers?.find(o => o.latestOffer)?.latestOffer;

    let data: KeyValuePair = {
      ...eventMetaData,
      load: mapToAdobeLoadDetailPlusCashAdvance(position, load, options),
      search,
      sortBy,
      sortType: sortOrder,
      searchCorrelationId,
      sequence: {
        isInfinityWorkflow: true,
        timeGMT,
        sessionEventSequenceNumber: sequenceNumber,
      },
      reload: reloadData
    };

    if (latestOffer && [AvailableLoadOfferStatus.ACCEPTED, AvailableLoadOfferStatus.COUNTERED].includes(AvailableLoadOfferStatus[latestOffer.offerStatus])) {
      data.offerRate = latestOffer.price;
    }

    if (eventType == EVENT_TYPES.LOAD_BOOK_ERROR) {
      data.statusCode = statusCode;
      data.offerStatus = latestOffer?.offerStatus;
    }

    track({ event: eventType, ...data });
  }, [track, eventMetaData, sortBy, sortOrder, searchCriteria, searchCorrelationId]);

  return trackBookEvent;
}

function mapToAdobeLoadDetailPlusCashAdvance(position: number, load: AvailableLoadSummary, options?: OptionsBook) {

  let optionObj = {}
  if (typeof options?.cashAdvanceOptIn === "boolean") {
    optionObj = { cashAdvanceOptin: options.cashAdvanceOptIn.toString() }
  }

  const loadDetail = mapToAdobeLoadDetail(position, load);

  return {
    ...optionObj,
    ...loadDetail
  }
}

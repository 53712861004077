import React from 'react';
import moment from 'moment';
import { Moment } from 'moment';

import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';

interface TimingProps {
  start: Date | string;
  end: Date | string;
  withBreak?: boolean;
  withFlex?: boolean;
  hasSeparator?: boolean;
}

const TIME_FORMAT_12 = 'hh:mm A';
const TIME_FORMAT_24 = 'HH:mm';
const cutoffDate = moment('1975-01-01T00:00:00').toDate();

const processDate = (date: Date | string): Moment | null => {
  if (date && moment(date).toDate() > cutoffDate) {
    return moment(date);
  }
  return null;
};

const getDateRange = (start: Moment | null, end: Moment | null) => {
  const {startDate, endDate} = getDateRangeValues(start, end);
  if (startDate && !endDate) {
    return startDate;
  }
  if (endDate && !startDate) {
    return endDate;
  }
  if (!startDate && !endDate) {
    return '';
  }

  if (startDate === endDate) {
    return endDate;
  }

  return `${startDate}-${endDate}`;
};

export const getDateRangeString = (start?: Date, end?: Date) => {
  const startMoment = processDate(start);
  const endMoment = processDate(end);

  const {startDate, endDate} = getDateRangeValues(startMoment, endMoment);

  if (startDate && !endDate) {
    return startDate;
  }
  if (endDate && !startDate) {
    return endDate;
  }
  if (!startDate && !endDate) {
    return '';
  }

  if (startDate === endDate) {
    return endDate;
  }

  return `${startDate}-${endDate}`;
};

const getDateRangeValues = (start: Moment | null, end: Moment | null): {startDate?: string, endDate?: string} => {
  if (start && !end) {
    return {startDate: start.format('L')};
  }
  if (end && !start) {
    return {endDate: end.format('L')};
  }
  if (!start && !end) {
    return {};
  }

  const startDate = start.format('L');
  const endDate = end.format('L');

  if (startDate === endDate) {
    return {endDate};
  }

  return {startDate, endDate};
};

const getTimeRangeValues = (start: Moment | null, end: Moment | null, is12Hour = true): {startTime?: string, endTime?: string} => {
  const TIME_FORMAT = is12Hour ? TIME_FORMAT_12 : TIME_FORMAT_24;
  if (start && !end) {
    return {startTime: start.format(TIME_FORMAT)};
  }
  if (end && !start) {
    return {endTime: end.format(TIME_FORMAT)};
  }
  if (!start && !end) {
    return {};
  }

  const startTime = start.format(TIME_FORMAT);
  const endTime = end.format(TIME_FORMAT);

  if (startTime === endTime) {
    return {endTime};
  }

  return {startTime, endTime};
};

const getTimeRange = (start: Moment | null, end: Moment | null, is12Hour = true) => {
  const resources = useResources();
  const {startTime, endTime} = getTimeRangeValues(start, end, is12Hour);
  if (startTime && !endTime) {
    return <span className="no-wrap" aria-label={`${resources.TIME} ${startTime}`}>{startTime}</span>;
  }
  if (endTime && !startTime) {
    return <span className="no-wrap" aria-label={`${resources.TIME} ${endTime}`}>{endTime}</span>;
  }
  if (!startTime && !endTime) {
    return '';
  }
  if (startTime === endTime) {
    return <span className="no-wrap" aria-label={`${resources.TIME} ${endTime}`}>{endTime}</span>;
  }
  return (
    <React.Fragment>
      <span className="no-wrap" aria-label={`${resources.START_TIME} ${startTime}`}>{startTime}</span> - <span className="no-wrap" aria-label={`${resources.END_TIME} ${endTime}`}>{endTime}</span>
    </React.Fragment>
  );
};

export const getTimeRangeString = (start?: Date, end?: Date, is12Hour = true) => {
  const startMoment = processDate(start);
  const endMoment = processDate(end);

  const {startTime, endTime} = getTimeRangeValues(startMoment, endMoment, is12Hour);
  if (startTime && !endTime) {
    return startTime;
  }
  if (endTime && !startTime) {
    return endTime;
  }
  if (!startTime && !endTime) {
    return '';
  }
  if (startTime === endTime) {
    return endTime;
  }
  return (
    `${startTime} - ${endTime}`
  );
};

export const Timing = (props: TimingProps) => {
  const user = useStoredUser();
  const start = processDate(props.start);
  const end = processDate(props.end);
  const dateRange = getDateRange(start, end);
  const timeRange = getTimeRange(start, end, user.is12Hour());
  const dateTimeSeparator = (props.hasSeparator && timeRange) ? <span aria-hidden={true}> • </span> : ' ';
  const separator = (timeRange && props.withBreak) ? <br/> : dateTimeSeparator;

  if (props.withFlex) {
    return <>
      <span>{dateRange}</span>
      {timeRange ? <span>{timeRange}</span> : ''}
    </>
  }

  return (<span className="timing-component">{dateRange}{(timeRange && props.withBreak) ? <br/> : separator}{timeRange}</span>);
};

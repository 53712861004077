import React from 'react';
import { WarningAmber } from '@mui/icons-material'; 
import { ComponentConnectorFactory, ResourceProps } from 'store/component-connector';

export interface EndorsableLoad {
  isTankerEndorsementRequired: boolean;
  isHazMat: boolean;
  isRegulatedByStf: boolean;
}

const getEndorsementList = (load: EndorsableLoad, resources, separator) => {
  const endorsements = [];
  if (load.isTankerEndorsementRequired) {
    endorsements.push(resources.TANKER);
  }
  if (load.isHazMat) {
    endorsements.push(resources.HazMat_No_Colon);
  }
  if (load.isRegulatedByStf) {
    endorsements.push(resources.STF);
  }
  return endorsements.join(separator);
};

interface OwnProps {
  load: EndorsableLoad;
  separator?: string;
}

type ConnectResourceKeys = 'STF' | 'TANKER' | 'HazMat_No_Colon';

type Props = OwnProps & ResourceProps<ConnectResourceKeys>;

export const EndorsementListContainer: React.FunctionComponent<Props> = ({ load, resources, separator = ', ' }) =>
    <span>
    {load.isRegulatedByStf && <WarningAmber style={{ marginRight: "2px", verticalAlign: "middle", color:"#B54D08", height:"19px" }} />}
    {getEndorsementList(load, resources, separator)} 
    </span>;

export const EndorsementList = ComponentConnectorFactory<OwnProps, {}, {}, ConnectResourceKeys>()
  .withResources<ConnectResourceKeys>('STF', 'TANKER', 'HazMat_No_Colon')
  .connect(EndorsementListContainer);

import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useMemo } from 'react';

export function useIsBookUnavailable(load: AvailableLoadSummary): boolean {
  const failedBooks = useSelector(state => state.findLoads.results.failedBooks);
  return useMemo(() => !!failedBooks?.length && !!load && failedBooks.some(loadNumber => loadNumber === load.number),
    [failedBooks, failedBooks?.length, load]
  );
}
